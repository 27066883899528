body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: var(--font-family);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none !important;
}
/* body {
  margin: 0;
  padding: 0;
  background-color: #17a2b8 !important;
  height: 100vh;
}
#login .container #login-row #login-column #login-box {
  margin-top: 120px;
  max-width: 600px;
  height: 320px;
  border: 1px solid #9C9C9C;
  background-color: #EAEAEA;
}
#login .container #login-row #login-column #login-box #login-form {
  padding: 20px;
}
#login .container #login-row #login-column #login-box #login-form #register-link {
  margin-top: -85px;
} */

.dropdown-container {
  width: 300px;
}
.sidebar-nav a{
  cursor: pointer;
}
.autoheight{
  height: 450px;
  overflow-x: auto;
  /* padding: 20px; */
}
.css-13cymwt-control{
  font-size: 13px;
}
.autoheight::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

.autoheight::-webkit-scrollbar-track {
  background: rgb(182, 182, 182);        /* color of the tracking area */
}

.autoheight::-webkit-scrollbar-thumb {
  background-color: blue;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid orange;  /* creates padding around scroll thumb */
}