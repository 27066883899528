/* .App {
  text-align: center;
} */
.paginati {
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  padding: 0 20px 20px;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
  border: 1px solid #dee2e6 !important;
  /* border-width: 0 0 1px 0 !important; */
  padding: 0.2rem 0.3rem !important;
}
.p-datatable-header {
  text-align: end;
}
.p-datatable-thead tr:first-child th{
  background: #4399eb;
}